require("./bootstrap");
//import http from "./axios";
import { createApp, defineAsyncComponent } from "vue";
import moment from "moment";
//import store from "./store";
//import BootstrapVueNext from 'bootstrap-vue-next';

import {createBootstrap} from 'bootstrap-vue-next'

// Add the necessary CSS
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import ScrollToTop from "./components/ScrollToTop.vue";
import Hotline from "./components/Hotline.vue";
import Breadcrumb from "./components/home/Breadcrumb.vue";
//import AmLich from "./components/home/AmLich.vue";
//const ElementPlus = defineAsyncComponent(()=> import('element-plus')) ;
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'

import http from "./fromFetch";

const SummernoteEditor = defineAsyncComponent(()=> import('./components/SummernoteEditor.vue')) ;
const IconFload = defineAsyncComponent(()=> import('./components/IconFload.vue'));
const VideoPlayerPip = defineAsyncComponent(()=> import('./components/VideoPlayerPip.vue'));
//const AmLich = defineAsyncComponent(()=> import('./components/home/AmLich.vue'));
import AmLich from "./components/home/AmLich.vue";

//import BootstrapTable from "./plugins/table";

//const BootstrapTable = defineAsyncComponent(()=> import("./plugins/table");
const izoomfancybox = defineAsyncComponent(()=> import('./components/izoomfancybox.vue'));
const SelectImage = defineAsyncComponent(()=> import('./components/SelectImage.vue'));
const MenuBarHome = defineAsyncComponent(()=> import('./components/MenuBar.vue'));
//const MarqueeText  = defineAsyncComponent(()=> import('vue-marquee-text-component'));
//import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
//const InnerImageZoom = defineAsyncComponent(()=> import('vue-inner-image-zoom'));

//const VueImageZoomer = defineAsyncComponent(()=> import('./components/VueImageZoomer.vue'));
//import { VueImageZoomer } from 'vue-image-zoomer'
//import 'vue-image-zoomer/dist/style.css';

//import InnerImageZoom from 'vue-inner-image-zoom';
//const Vue3Marquee = defineAsyncComponent(()=> import('vue3-marquee'));
//import 'mdb-vue-ui-kit/css/mdb.min.css';
import MarqueeText from './components/MarqueeText.vue';
//import { Vue3Marquee } from 'vue3-marquee'
//import 'vue3-marquee/dist/style.css'

//import MenuBar from "./components/MenuBar.vue";

moment.locale("id");
let numberFormat = function(value) {
    let val = (value / 1).toFixed(0).replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
//import InputMask from 'primevue/inputmask';
const InputMask = defineAsyncComponent(()=> import('primevue/inputmask'));
const app = createApp({
    mixins: [myMixin, appMixin],
    components: {
        //BootstrapTable,
        ScrollToTop,
        SummernoteEditor,
        MarqueeText,
        izoomfancybox, //xzoom
        VideoPlayerPip,
        //VideoPlayer,
        Hotline,
        Breadcrumb,
        IconFload,
        //InnerImageZoom,
        //VueImageZoomer,
        MenuBarHome,
        AmLich,
        //Vue3Marquee,
    },
    data() {
        return {
            isMobile: false,
        }
    },
    mounted () {
        this.onScreenResize()
        window.addEventListener('resize', this.onScreenResize, { passive: true })
    },
    methods: {
        onScreenResize () {
            this.isMobile = window.innerWidth < 768
        }
    },
    beforeDestroy () {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onScreenResize, { passive: true })
        }
    },
})
    .use(ElementPlus)
    //.use(store)
    //.use(Vue3Marquee)
    //.use(BootstrapVueNext)
    .use(createBootstrap({components: true, directives: true})) // Change this line
    .use(BootstrapIconsPlugin);
app.config.globalProperties.moment = moment;
app.config.globalProperties.$http = http;
app.config.globalProperties.numberFormat = numberFormat;
//app.directive('maska', Maska.maska);
window.app = app.mount("#app");
