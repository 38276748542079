<template>
<nav id="breadcrumb_nav" class="main-canvas" aria-label="Breadcrumb">
    <ol class="dds__breadcrumb">
        <template v-for="(item, index) in items">
            <li v-if="index == 0" class="dds__breadcrumb__item home-anchor">
                <a :href="item.href" title="Trang chủ">
                    <svg aria-hidden="true" class="icon-home" style="height:16px;width:16px;fill:#6E6E6E;margin-top: -5px;">
                        <defs>
                            <symbol id="dds__home" viewBox="0 0 32 32"><path d="M30.821 17.746l-3.1-3.322v-0.055h-0.051l-11.667-12.499-11.671 12.499h-0.070v0.075l-3.083 3.302 1.376 1.284 1.705-1.828v12.928h8.994v-10.488h5.468v10.488h8.994v-12.947l1.724 1.847 1.38-1.284zM25.838 28.248h-5.229v-10.488h-9.233v10.488h-5.231v-13.064l9.858-10.556 9.837 10.539v13.080z"></path></symbol>
                        </defs>
                        <use href="#dds__home"></use></svg>
                    <span>{{ item.name }}</span>
                </a>
            </li>
            <li v-else-if="index < items.length - 1"  class="dds__breadcrumb__item">
                <a :href="item.href" :title=" item.name">
                        <span> {{ item.name }}</span>
                    </a>
            </li>
            <li v-else-if="index == items.length - 1"  class="dds__breadcrumb__item last">
                <span> {{ item.name }}</span>
            </li>
            <li class="dds__breadcrumb__item" v-else>
                <a :href="item.href" :title="item.name">
                    <span property="name">{{ item.name }}</span>
                </a>
            </li>
        </template>
    </ol>
</nav>
</template>
<script>
//import 'bootstrap/dist/css/bootstrap.min.css';
export default {
    props: {
        items: {
                type: Array,
                default: () => [],
                required: true,
            },
    },
    // mounted() {
    //     console.log(this.items);
    // }
};
</script>
<style scoped>
.dds__breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 2px;
    margin: 0
}

.dds__breadcrumb__item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.dds__breadcrumb__item:first-of-type a {
    line-height: 1rem;
    text-decoration: none;
    padding: .25rem
}

.dds__breadcrumb__item:first-of-type a:hover {
    background-color: #d9f5fd
}

.dds__breadcrumb__item:first-of-type a i,.dds__breadcrumb__item:first-of-type a svg {
    border-radius: 2px;
    fill: #6e6e6e;
    width: .875rem;
    height: .875rem
}

.dds__breadcrumb__item:first-of-type a i:hover,.dds__breadcrumb__item:first-of-type a svg:hover {
    background-color: #d9f5fd;
    fill: #0063b8
}

.dds__breadcrumb__item:last-of-type {
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: .005em;
    line-height: 1.25rem
}

.dds__breadcrumb__item:last-of-type a {
    color: #0e0e0e;
    pointer-events: none
}

.dds__breadcrumb__item:last-of-type a:focus {
    text-decoration: none
}

.dds__breadcrumb__item:not(:last-of-type)::after {
    content: "/";
    width: 1.5rem;
    padding: 0 .25rem;
    text-align: center;
    color: #6e6e6e
}

.dds__breadcrumb__item a {
    font-size: .875rem;
    line-height: 1.25rem;
    letter-spacing: .005em;
    font-weight: 400;
    color: #6e6e6e;
    text-decoration: none;
    padding: .125rem .25rem;
    fill: currentColor
}

.dds__breadcrumb__item a:hover {
    text-decoration: underline;
    color: #0063b8
}

.dds__breadcrumb__item a:focus {
    color: #0672cb;
    text-decoration: underline
}

.dds__breadcrumb__mobile-icon {
    display: none
}

@media screen and (max-width: 480px) {
    .dds__breadcrumb__item {
        display:none!important
    }

    .dds__breadcrumb__item:nth-last-of-type(2) {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .dds__breadcrumb__item:nth-last-of-type(2)::after {
        content: ""
    }

    .dds__breadcrumb__mobile-icon {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        height: 1rem;
        width: 2rem;
        fill: #6e6e6e
    }
}
.home-anchor:first-of-type a svg:hover,.home-anchor:first-of-type a:hover {
    background-color: transparent!important
}
</style>
