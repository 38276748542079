<template>
  <div class="row">
    <div class="col">
      <h2 class="d-grid gap-2">
        <button class="btn btn-success" type="button" @click="clickBtn">LỊCH</button>
      </h2>
      <div id="card_lich_am" v-show="show" v-html="html"></div>
    </div>
  </div>
</template>
<script>
import jQuery from "jquery";
require("../js/amlich");
export default {
  data() {
    return {
      html: "",
      show: false,
    };
  },
  methods: {
    clickBtn() {
      this.show = !this.show;
    },
  },
  mounted() {
    this.html = amLichVietNam.strDateInfoLich();
  },
};
</script>
