import { fromFetch } from "rxjs/fetch";
//import { switchMap, of, catchError } from "rxjs";
const token = document.head.querySelector('meta[name="csrf-token"]');
if (!token)
    console.error(
        "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
    );
const http = {
    defaults: {
        baseURL: "",
    },
    get: function (url) {
        return fromFetch(this.defaults.baseURL + url, {
            headers: {
                "X-CSRF-TOKEN": token.content,
            },
            credentials: "include",
            selector: async (response) => await response.json()
        });
    },
    getRoot: function (url) {
        return fromFetch(url, {
            mode: 'no-cors',
            headers: {
                "X-CSRF-TOKEN": token.content
            },
            credentials: 'include',
            selector: async (response) => await response.json()
        });
    },
    post: function (url, body) {
        return fromFetch(this.defaults.baseURL + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": token.content,
            },
            body: JSON.stringify(body),
            credentials: "include",
            selector: async (response) => await response.json()
        });
        //.subscribe({
        //next: response => /* ... */
        //error: err => /* ... */
        //});
    },
    upload: function (url, form) {
        return fromFetch(this.defaults.baseURL + url, {
            method: "POST",
            headers: {
                "X-CSRF-TOKEN": token.content,
                "X-Requested-With": "XMLHttpRequest",
            },
            credentials: "include",
            body: form,
            selector: async (response) => await response.json()
        });
    },
    download: async function (url, filename) {
        await fromFetch(this.defaults.baseURL + url, {
            headers: {
                "X-CSRF-TOKEN": token.content,
            },
            credentials: "include",
            selector: async (response) => await response.blob()
        }).subscribe({
            next: response => {
                let elm = document.createElement('a');  // CREATE A LINK ELEMENT IN DOM
                elm.href = URL.createObjectURL(response);  // SET LINK ELEMENTS CONTENTS
                elm.setAttribute('download', filename); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD, FILENAME PARAM AUTOMATICALLY
                elm.click();                             // TRIGGER ELEMENT TO DOWNLOAD
                elm.remove();
            },
            error: error => {
                //this.errorMessage = error;
                this.$notify({
                    message: 'Có lỗi!' + error,
                    type: 'error',
                    showClose: true
                });
                console.error('There was an error!', error);
            }
        });
    },
    downloadPost: async function (url, body, filename) {
        await fromFetch(this.defaults.baseURL + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": token.content,
            },
            body: JSON.stringify(body),
            credentials: "include",
            selector: async (response) => await response.blob()
        }).subscribe({
            next: response => {
                let elm = document.createElement('a');  // CREATE A LINK ELEMENT IN DOM
                elm.href = URL.createObjectURL(response);  // SET LINK ELEMENTS CONTENTS
                elm.setAttribute('download', filename); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD, FILENAME PARAM AUTOMATICALLY
                elm.click();                             // TRIGGER ELEMENT TO DOWNLOAD
                elm.remove();
            },
            error: error => {
                //this.errorMessage = error;
                this.$notify({
                    message: 'Có lỗi!' + error,
                    type: 'error',
                    showClose: true
                });
                console.error('There was an error!', error);
            }
        });
    },
};
export default http;
/*
const data$ = fromFetch("https://api.github.com/users?per_page=5").pipe(
    switchMap((response) => {
        if (response.ok) {
            // OK return data
            return response.json();
        } else {
            // Server is returning a status requiring the client to try something else.
            return of({ error: true, message: `Error ${response.status}` });
        }
    }),
    catchError((err) => {
        // Network or other error, handle appropriately
        console.error(err);
        return of({ error: true, message: err.message });
    })
);

data$.subscribe({
  next: result => console.log(result),
  complete: () => console.log('done')
});
*/
/*
this.$http.upload("/api/thongtin-store-file", form).subscribe({
    next: (data) => {
        if (data.hasOwnProperty("result")) {
            this.logo = data.result.logo;
            this.$notify("Upload Ok!");
        } else {
            this.$notify({
                message: data.errors[0],
                type: "error",
                showClose: true,
            });
        }
    },
    error: (error) => {
        //this.errorMessage = error;
        this.$notify({
            message: "Có lỗi!" + error,
            type: "error",
            showClose: true,
        });
        console.error("There was an error!", error);
    },
});
*/
