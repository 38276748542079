<template>
  <vue3-marquee :duration="marqueeDuration" :pause-on-hover="true"
    ><slot></slot
  ></vue3-marquee>
</template>

<script>
import { Vue3Marquee } from "vue3-marquee";
import "vue3-marquee/dist/style.css";

export default {
  components: {
    Vue3Marquee,
  },
  props: {
    time: {
      default: 20,
      type: Number,
      required: false,
    }, // in seconds
    length: {
      default: 20,
      type: Number,
      required: false,
    },
  },
  computed: {
    marqueeDuration() {
      return this.time * Math.ceil(this.length / 100.0);
    },
  },
};
</script>
